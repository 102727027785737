import crossFetch from 'cross-fetch'
import fetchCookie from 'fetch-cookie'

export const fetch = (url: Request | string | URL, options: RequestInit | undefined) => {
  // Log the headers here
  console.log('Fetch Request Headers:', url, options?.headers)

  // Call the original fetch function
  return fetchCookie(crossFetch)(url, options)
}
